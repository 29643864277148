<div class="modal-dialog container">
  <div class="modal-content">
    <div class="modal-header">Generate upload link to {{ this.folder.name }}</div>

    <div class="modal-body">
      <p>Create multiple directories by entering one directory per line.</p>
      <label for="message">Type message for uploader</label>
      <textarea id="message" [(ngModel)]="message" rows="4" style="width: 100%"
                cdkTextareaAutosize
      ></textarea>
      <p *ngIf="this.uploadurl !== null">
        <a class="cl-a cl-a-external" href="{{ this.uploadurl }}" target=”_blank” >Upload link to {{ this.folder.name }}</a>
      </p>

    </div>
    <div class="modal-footer">
      <span class="modal-footer-left cl-spacing-box cl-spacing-none">
           <button class="btn cl-btn-outline-text" *ngIf="this.uploadurl === null"
                   (click)="genLink()">Generate link</button>
          <button class="btn cl-btn-outline-text" *ngIf="this.uploadurl"
                  (click)="copyClipboard()">Copy to Clipboard</button>
      </span>
      <span class="modal-footer-center cl-spacing-box cl-spacing-none"></span>
      <span class="modal-footer-right cl-spacing-box cl-spacing-none">
            <button type="button" class="btn cl-btn-primary it-ok"
                    (click)=" dialogRef.close()">
                      <span>Close</span><i class="fas fa-angle-right"></i></button>
      </span>
    </div>
  </div>
</div>
