import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {IfeFormat} from '../../../target_systems';


@Component({
  selector: 'app-above-default-format-specific',
  templateUrl: './above-default-format-specific.component.html',
  styleUrls: ['./above-default-format-specific.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AboveDefaultFormatSpecificComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AboveDefaultFormatSpecificComponent,
      multi: true
    }
  ]
})
export class AboveDefaultFormatSpecificComponent implements OnInit, ControlValueAccessor, OnChanges {
  static twoMonthFromNow = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 2, 1)
  static DEFAULTS = {
    license_expiration_date: `${AboveDefaultFormatSpecificComponent.twoMonthFromNow.getDate()}.${AboveDefaultFormatSpecificComponent.twoMonthFromNow.getMonth()}.${AboveDefaultFormatSpecificComponent.twoMonthFromNow.getFullYear()}`,
    preroll: '',
    westent_cycle_name: '',
    card_url: '',
    card_duration: '',
    watermark: false,
    video_bitrate: null,
    thales_fastpath_airline: '',
    dfe_ca_bits: '48',
    dfe_key_id: '5EF8A7AB75624939AF735ACC42F5C54D',
    dfe_key: 'AC9FC2B4BD3B258D53E6AC8AB6E14C07',
    dfe_iv: '0000000000000000',
    dfe_disable_drm: '0',
  };

  @Input()
  parentForm: FormGroup;

  @Input() ifeFormat: IfeFormat;

  form: FormGroup;

  protected readonly environment = environment;

  changed: any = () => {
  };
  touched: any = () => {
  };

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      license_expiration_date: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
      preroll: '',
      westent_cycle_name: '',
      card_url: '',
      card_duration: '',
      video_bitrate: null,
      thales_fastpath_airline: '',
      dfe_ca_bits: '48',
      dfe_key_id: '5EF8A7AB75624939AF735ACC42F5C54D',
      dfe_key: 'AC9FC2B4BD3B258D53E6AC8AB6E14C07',
      dfe_iv: '0000000000000000',
      dfe_disable_drm: '0',
    });
    this.form.valueChanges.subscribe((val) => {
      if (this.touched) {
        this.touched();
      }
      if (this.changed) {
        this.changed(val);
      }
    });
  }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState ?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ifeFormat && this.form) {
      if (this.ifeFormat.vtk_template === 'nice_media') {
        this.form.controls['license_expiration_date'].clearValidators();
        this.form.controls['license_expiration_date'].addValidators(Validators.required);
      } else {
        this.form.controls['license_expiration_date'].clearValidators();
      }
      this.form.controls['license_expiration_date'].updateValueAndValidity();
      if (this.ifeFormat.vtk_template.startsWith('thales-avant-up-avod-')) {
        this.form.controls['thales_fastpath_airline'].clearValidators();
        this.form.controls['thales_fastpath_airline'].addValidators(Validators.required);
        this.form.controls['video_bitrate'].clearValidators();
        this.form.controls['video_bitrate'].addValidators([
          Validators.min(1000000),
          Validators.max(100000000)
        ]);
      } else {
        this.form.controls['thales_fastpath_airline'].clearValidators();
        this.form.controls['video_bitrate'].clearValidators();
      }
      this.form.controls['thales_fastpath_airline'].updateValueAndValidity();
      this.form.controls['video_bitrate'].updateValueAndValidity();
    }
  }

}
