import {Component, Inject, Input, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {mergeMap, skipWhile, take} from 'rxjs/operators';
import {ControlContainer, FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {BannerService} from '../../../banner/banner.service';
import {Apollo} from 'apollo-angular';
import {Airline, CodecType, Mutation, ProcessStateEnum, Query} from '../../../types-workflow';

import {GET_PROCESS, START_WORKFLOW_AERQ_DEFAULT} from '../../../queries';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {Po2024Service} from '../../po-2024.service';
import {BasicPoitemInfoComponent} from '../basics-poitem-info/basic-poitem-info.component';
import {TrackSelectionComponent} from '../tracks-selection/track-selection.component';
import {AerqDefaultFormatSpecificComponent} from '../aerq-default-format-specific/aerq-default-format-specific.component';
import {clean_datetime, shortenJanusOrg} from '../../utils';
import {Organization} from '../../../types';


@Component({
  selector: 'app-aerq-default-po',
  templateUrl: './aerq-default-po.component.html',
  styleUrls: ['./aerq-default-po.component.css'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class AerqDefaultPoComponent implements OnInit, OnDestroy {

  @Input()
  organization: Organization;

  @Input()
  airlines: Airline[] = []

  poFormGroup: FormGroup;


  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }

  constructor(
    private apollo: Apollo,
    public snackBar: MatSnackBar,
    @Inject('aerq_default') protected poService: Po2024Service,
    private formBuilder: FormBuilder,
    private banner: BannerService,
    private router: Router
  ) {

    this.poFormGroup = formBuilder.group({
      airline: [null, Validators.required],
      po_number: [clean_datetime(new Date()), Validators.required],
      aerq_airline: [null, Validators.required],
      aerq_project: [null, Validators.required],
      aerq_version: [Math.round(new Date().getTime() / 1000), Validators.required],
      vtk_template: ['aerq-ife-hsd', Validators.required],
      auto_publish: [true, Validators.required],
      poItems: formBuilder.array([]),
    });


    this.poService.getFormState().pipe(take(1)).subscribe(savedForm => {
      if (savedForm) {
        savedForm.poItems.forEach(poItem => (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(poItem)));
        this.poFormGroup.setValue(savedForm, {emitEvent: false})
      }
    });
    this.poService.poItemToAdd$.subscribe(data => {
      console.log('poItemToAdd$')
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemInfoComponent.DEFAULTS,
              tracks: TrackSelectionComponent.DEFAULTS,
              format_specific_data: AerqDefaultFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    this.poService.poItemToRemove$.subscribe(id => {
      console.log('poItemToRemove$')
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    });
  }

  ngOnInit() {
    if (this.airlines.length === 1) {
      this.poFormGroup.controls['airline'].setValue(this.airlines[0].iata_code);
    }
  }


  submit_po() {

    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      alert('Invalid')
      this.poFormGroup.markAllAsTouched();
      return
    }
    if (this.poFormGroup.value.poItems.length === 0) {
      alert('No PO items')
    }
    const workflows = []
    for (const poItem of this.poFormGroup.value.poItems) {
      const tracks = []

      if (this.poFormGroup.value.vtk_template !== 'aerq-ife-audio') {
        tracks.push({
          'codec_type': CodecType.Video
        })
      }

      tracks.push(
        ...poItem.tracks.audio.map(t => {
          return {
            'codec_type': CodecType.Audio,
            'lang': t
          }
        })
      )
      tracks.push(
        ...poItem.tracks.subtitles.map(t => {
          return {
            'codec_type': CodecType.Subtitle,
            'lang': t
          }
        })
      )
      tracks.push(
        ...poItem.tracks.cc.map(t => {
          return {
            'codec_type': CodecType.Closedcaption,
            'lang': t
          }
        })
      )

      console.log('starting workflow')
      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_AERQ_DEFAULT,
        variables: {
          filename: poItem.basics.filename.trim(),
          input_brefix: poItem.basics.input_brefix,
          po_item_id: poItem.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          aerq_airline: this.poFormGroup.value.aerq_airline,
          aerq_project: this.poFormGroup.value.aerq_project,
          aerq_version: this.poFormGroup.value.aerq_version,
          watermark: true,
          vtk_template: this.poFormGroup.value.vtk_template,
          format_specific_data: JSON.stringify(poItem.format_specific_data),
          tracks: tracks

        }

      }).pipe(
        mergeMap(d => {
          const processId = d.data.start_workflow_aerq_default.id
          return this.apollo.use('workflow').watchQuery<Query>(
            {
              query: GET_PROCESS,
              pollInterval: 2000,
              variables: {
                id: processId
              }
            }
          ).valueChanges
        }),
        skipWhile(d => d.data.process.state === ProcessStateEnum.InProgress),
        take(1)
      ))
    }

    const sb_ref = this.snackBar.open('Workflows started.', `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
      {
        duration: 5000
      })

    sb_ref.onAction().subscribe(() => {
      this.router.navigate(
        ['/o', this.organization.id, 'workflows',
          this.poFormGroup.value.airline,
          `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`])
    })
    forkJoin(workflows).subscribe(
      r => {
        sb_ref.dismiss();
        this.snackBar.open(`All workflows in ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number} have completed.`,
          `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
          {
            duration: 5000
          }).onAction().subscribe(() => this.router.navigate(
          ['/workflows',
            this.poFormGroup.value.airline,
            `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`]))
      },
      err => {
        this.banner.open('Workflow submission failed: ' + err, ['Close'])
      }
    )

  }

  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  ngOnDestroy(): void {
    this.poService.setFormState(this.poFormGroup.value)
  }

}
