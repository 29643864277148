import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.log('INIT');

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry_dsn,
    attachStacktrace: true,
    beforeSend: (event, hint) => {
      const error = hint.originalException as Error;
      if (error && error.message.match(/.+https:\/\/preview-.+\/metadata.json/i)) {
        // do not send known issue
        return null;
      }
      return event;
    },
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
