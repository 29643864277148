import {ErrorHandler, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {FileSizePipe} from './pipes/file-size-pipe';
import {RemainingTimePipe} from './pipes/remaining-time-pipe';
import {EstimatedRestorationTimePipe} from './pipes/estimated-restoration-time-pipe';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {SearchPageComponent} from './components/search/search-page.component';
import {AddBreakersPipe, LastSegmentPipe, RemoveLastSegmentPipe} from './pipes/remove-last-segment-pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './auth.guard';
import {LoginPageComponent} from './components/login-page.component';
import {ListPreviewsForAirlineComponent} from './components/previews/list-previews-for-airline/list-previews.component';
import {ListPosComponent} from './components/workflows/list-pos.component';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {AboveDefaultPoComponent} from './components/submit-po-2024/above-default-po/above-default-po.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ListPoItems2Component, RemovePipe} from './components/workflows/list-po-items2.component';
import {GenerateUploadLinkComponent} from './components/s3-browse/generate-upload-link-dialog/generate-upload-link-dialog.component';
import {RenameFileDialogComponent} from './components/s3-browse/rename-file-dialog/rename-file-dialog.component';
import {MkdirDialogComponent} from './components/s3-browse/mkdir-dialog/mkdir-dialog.component';
import {AppRoutingModule} from './app-routing.module';
import {PlayerComponent} from './components/previews/player/player.component';
import {PreviewsComponent} from './components/previews/previews.component';
import {WorkflowsComponent} from './components/workflows/workflows.component';
import {OAuthModule, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ngsw} from '../environments/ngsw-state';
import {FileIconComponent} from './components/file-icon/file-icon.component';
import {VersionUpgraderComponent} from './version-upgrader.component';
import {APOLLO_NAMED_OPTIONS, ApolloModule} from 'apollo-angular';
import * as Sentry from '@sentry/angular-ivy';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {BannerOutlet} from './banner/banner.component';
import {BannerService} from './banner/banner.service';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {MFASetupComponent} from './components/user-profile/mfasetup/mfasetup.component';
import {QRCodeModule} from 'angularx-qrcode';
import {RemindMFAComponent} from './components/remind-mfa/remind-mfa.component';
import {HttpLink} from 'apollo-angular/http';
import {LayoutModule} from '@angular/cdk/layout';
import {DownloadEncodeDialogComponent} from './components/workflows/download-encode-dialog/download-encode-dialog.component';
import {NgIdleModule} from '@ng-idle/core';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {PreviewPoitemComponent} from './components/previews/display-poitem-preview/preview-poitem.component';
import {WarnLogoutComponent} from './components/warn-logout/warn-logout.component';
import {AutoLogoutComponent} from './components/auto-logout/auto-logout.component';
import {LoggedInComponent} from './components/logged-in/logged-in.component';
import {FileDetailsComponent} from './components/s3-browse/file-details/file-details.component';
import {FolderComponent} from './components/s3-browse/folder/folder.component';
import {AssetIconComponent} from './components/s3-browse/file-icon/asset-icon.component';
import {NgxGalleryModule} from '@vinlos/ngx-gallery';
import {MoveFileDialogComponent} from './components/s3-browse/move-file-dialog/move-file-dialog.component';
import {MfaGuard} from './mfa.guard';
import {FinalizePoDialogComponent} from './components/workflows/finalize-po-dialog/finalize-po-dialog.component';
import {Po2024Service} from './components/po-2024.service';
import {FolderInfoComponent} from './components/s3-browse/folder-info/folder-info.component';
import {DummyComponent} from './components/dummy/dummy.component';
import {LogoAreaComponent} from './components/logo-area/logo-area.component';
import {SideNavService} from './services/side-nav.service';
import {HeadlineSectionComponent} from './components/headline-section/headline-section.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PlayerSelectAudioComponent} from './components/previews/player/player-select-audio/player-select-audio.component';
import {PlayerSelectTypeComponent} from './components/previews/player/player-select-type/player-select-type.component';
import {PlayerSelectVideoComponent} from './components/previews/player/player-select-video/player-select-video.component';
import {PlayerSelectTextComponent} from './components/previews/player/player-select-text/player-select-text.component';
import {ApolloLink, InMemoryCache, InMemoryCacheConfig} from '@apollo/client/core';
import {VodWatermarkPoComponent} from './components/submit-po-2024/vod-watermark-po/vod-watermark-po.component';
import {VodDefaultPoComponent} from './components/submit-po-2024/vod-default-po/vod-default-po.component';
import {ProcessLinkComponent} from './components/process-link/process-link.component';
import {DurationPipe} from './pipes/duration.pipe';
import {VodDemoPlayerPipe} from './pipes/demo-player.pipe';
import {ArchiveRestoreDialogComponent, FileRestorePlanPipe} from './components/archive-restore-dialog/archive-restore-dialog.component';
import {S3LinkComponent} from './components/s3-link/s3-link.component';
import {PortalIdpService} from './services/portal-idp.service';
import {BasicPoitemInfoComponent} from './components/submit-po-2024/basics-poitem-info/basic-poitem-info.component';
import {
  VodDefaultFormatSpecificComponent
} from './components/submit-po-2024/vod-default-format-specific/vod-default-format-specific.component';

import {LanguageSelectComponent} from './components/submit-po-2024/tracks-selection/language-select/language-select.component';
import {LangNamePipe} from './components/submit-po-2024/tracks-selection/language-select/lang-name.pipe';
import {OrganizationSwitcherComponent} from './components/organization-select/organization-switcher.component';
import {ClTabsComponent} from './components/cl-tabs/cl-tabs.component';
import {ClTabComponent} from './components/cl-tabs/cl-tab/cl-tab.component';
import {PortalModule} from '@angular/cdk/portal';
import {ContainsPipe} from './components/s3-browse/contains.pipe';
import {FooterComponent} from './components/footer/footer.component';
import {AirlineSelectComponent} from './components/airline-select/airline-select.component';
import {FilterSectionComponent} from './components/filter-section/filter-section.component';
import {FolderAssetViewComponent} from './components/s3-browse/folder-asset-view/folder-asset-view.component';
import {FolderListingComponent} from './components/s3-browse/folder-listing/folder-listing.component';
import {DEFAULT_DIALOG_CONFIG, DialogModule} from '@angular/cdk/dialog';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {UndeleteDialogComponent} from './undelete-dialog/undelete-dialog.component';
import {FileFolderLinkPipe} from './components/file-folder-link.pipe';
import {PoMetaService} from './components/po-meta.service';
import {MezzPreviewPlayerComponent} from './components/s3-browse/folder-asset-view/mezz-preview-player.component';

import {AerqDefaultPoComponent} from './components/submit-po-2024/aerq-po/aerq-default-po.component';
import {
  AerqDefaultFormatSpecificComponent
} from './components/submit-po-2024/aerq-default-format-specific/aerq-default-format-specific.component';
import {TrackSelectionComponent} from './components/submit-po-2024/tracks-selection/track-selection.component';
import {BasicPoitemVodInfoComponent} from './components/submit-po-2024/basics-poitem-vod-info/basic-poitem-vod-info.component';
import {
  VodWatermarkFormatSpecificComponent
} from './components/submit-po-2024/vod-watermark-format-specific/vod-watermark-format-specific.component';
import {
  AboveDefaultFormatSpecificComponent
} from './components/submit-po-2024/above-default-format-specific/above-default-format-specific.component';
import {AboveDefaultSpecificComponent} from './components/submit-po-2024/above-default-specific/above-default-specific.component';
import {PoItemLineComponent} from './components/workflows/po-item-line/po-item-line.component';
import {PoItemDetailsComponent} from './components/workflows/po-item-details/po-item-details.component';
import {HomeComponent} from './components/s3-browse/home-component/home.component';
import {CommaSeparatedInputDirective} from './components/submit-po-2024/comma-separated-input.directive';
import {FlytedgeMetadataPreviewComponent} from './components/ifes/flytedge-metadata-preview/flytedge-metadata-preview.component';
import {FlytedgeComponent} from './components/ifes/flytedge/flytedge.component';
import {FlytedgeMetadataRawComponent} from './components/ifes/flytedge-metadata-raw/flytedge-metadata-raw.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {OrganizationService} from './services/organization.service';
import {TypePolicy} from '@apollo/client/cache/inmemory/policies';
import {IsAllowedAnywherePipe, IsAllowedPipe} from './pipes/has-action-pipe';
import {OrgShortenerPipe} from './pipes/org-shortener-pipe';
import {JanusUrnUuidExtractPipe} from './pipes/janus-urn-uuid-extract-pipe';


const providers: Provider[] = [
  BannerService,
  SideNavService,
  {
    provide: DEFAULT_DIALOG_CONFIG, useValue: {
      hasBackdrop: true,
      disableClose: true,
      width: '720px',
      'max-width': '100%',
    }
  },
  {
    provide: 'aerq_default',
    useFactory: (oAuthService: OAuthService) =>
      new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowAerqDefault'),
    deps: [OAuthService]
  },
  {
    provide: 'above_default',
    useFactory: (oAuthService: OAuthService) =>
      new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowDefault'),
    deps: [OAuthService]
  },
  {
    provide: 'vod_batch',
    useFactory: (oAuthService: OAuthService) =>
      new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowVodBatch'),
    deps: [OAuthService]
  },
  {
    provide: 'vod_default',
    useFactory: (oAuthService: OAuthService) =>
      new Po2024Service(oAuthService, 'contentplatform:workflow:Mutation/StartWorkflowVodDefault'),
    deps: [OAuthService]
  },
  PoMetaService,
  AuthGuard,
  MfaGuard,
  {provide: LocationStrategy, useClass: HashLocationStrategy},
  {provide: OAuthStorage, useValue: localStorage},
  PortalIdpService,
  {
    provide: APOLLO_NAMED_OPTIONS,
    useFactory: (httpLink: HttpLink, oAuthService: OAuthService, organizationIdService: OrganizationService) => {
      const authMiddleware = new ApolloLink((operation, forward) => {
        const accessToken = oAuthService.getAccessToken();
        let headers = operation.getContext().headers || new HttpHeaders();
        headers = headers.set('Authorization', accessToken);

        if (organizationIdService.organizationSnapshot && !headers.get('X-Castlabs-Organization')) {
          headers = headers.set('X-Castlabs-Organization', organizationIdService.organizationSnapshot.id);
        }

        operation.setContext({
          headers: headers
        });

        return forward(operation);
      });

      function createCache(organizationService: OrganizationService) {
        const generateCacheKey = (args) => {
          if (organizationService.organizationSnapshot) {
            if (!args) {
              return organizationService.organizationSnapshot?.id;
            } else {
              return `${organizationService.organizationSnapshot?.id}:${JSON.stringify(args)}`;
            }
          }
          if (!args) {
            return null;
          } else {
            return JSON.stringify(args);
          }
        };
        const headerAware = {
          keyArgs: false,
          read(existing, {args}) {
            const key = generateCacheKey(args)
            return existing ? existing[key] : undefined;
          },
          merge(existing = {}, incoming, {args}) {
            const key = generateCacheKey(args);
            return {
              ...existing,
              [key]: incoming,
            };
          },
        } as TypePolicy;

        const cacheParams = {

          typePolicies: {
            Query: {
              fields: {
                roots: headerAware,
                airlines: headerAware,
                policies: headerAware
              }
            },
            File: {
              fields: {
                archive: {
                  merge: true,
                },
              },
            },
            POItem: {
              fields: {
                // after a restart it takes a while until the process is updated on the poitem
                // and this makes sure that local cache updates are not overwritten by stale data from the server
                workflow_process: {
                  merge(existingRef, incomingRef, {readField, toReference}) {
                    // Resolve the references to get the actual objects
                    const existing = existingRef ? readField('start_date', existingRef) : undefined;
                    const incoming = incomingRef ? readField('start_date', incomingRef) : undefined;

                    if (existing === undefined) {
                      return incomingRef;
                    }
                    if (incoming === null && existing !== null) {
                      return existingRef;
                    }
                    if (existing > incoming) {
                      return existingRef;
                    }
                    return incomingRef;
                  }
                },
                publish_process: {
                  merge(existingRef, incomingRef, {readField, toReference}) {
                    // Resolve the references to get the actual objects
                    const existing = existingRef ? readField('start_date', existingRef) : undefined;
                    const incoming = incomingRef ? readField('start_date', incomingRef) : undefined;

                    if (existing === undefined) {
                      return incomingRef;
                    }
                    if (incoming === null && existing !== null) {
                      return existingRef;
                    }
                    if (existing > incoming) {
                      return existingRef;
                    }
                    return incomingRef;
                  }
                },
              },
            },
          },
        } as InMemoryCacheConfig;
        return new InMemoryCache(cacheParams);
      }

      return {
        'default': {
          cache: createCache(organizationIdService),
          connectToDevTools: true,
          link: ApolloLink.from([
            authMiddleware,
            httpLink.create({
              uri: environment.graphql
            })])
        },
        'workflow': {
          cache: createCache(organizationIdService),
          resolvers: {
            POItem: {
              format_specific_data_json(po_item, _args, {cache}) {
                return JSON.parse(po_item.format_specific_data);
              }
            }
          },
          link: ApolloLink.from([authMiddleware, httpLink.create({
            uri: environment.workflow_graphql
          })])
        }
      }
    },
    deps: [HttpLink, OAuthService, OrganizationService]
  },
  {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
];

if (environment.production) {
  providers.unshift(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    }
  );
}


@NgModule({
  declarations: [
    AppComponent, WarnLogoutComponent,
    SearchPageComponent, LoginPageComponent, MkdirDialogComponent,
    JanusUrnUuidExtractPipe, FileSizePipe, OrgShortenerPipe, RemoveLastSegmentPipe, LastSegmentPipe, AddBreakersPipe, IsAllowedPipe,
    IsAllowedAnywherePipe, ContainsPipe,
    ListPreviewsForAirlineComponent, ListPosComponent,
    ListPoItems2Component,
    AboveDefaultPoComponent, FolderInfoComponent,
    RemovePipe, GenerateUploadLinkComponent, RenameFileDialogComponent,
    MkdirDialogComponent, PreviewsComponent, PlayerComponent,
    WorkflowsComponent,
    FileIconComponent, VersionUpgraderComponent,
    FlytedgeMetadataPreviewComponent, FlytedgeComponent, FlytedgeMetadataRawComponent,
    BannerOutlet,
    UserProfileComponent,
    MFASetupComponent, RemindMFAComponent, DownloadEncodeDialogComponent,
    PreviewPoitemComponent,
    AutoLogoutComponent,
    LoggedInComponent,
    CommaSeparatedInputDirective,
    RemainingTimePipe,
    EstimatedRestorationTimePipe,
    FileRestorePlanPipe,
    FolderComponent,
    AssetIconComponent,
    FileDetailsComponent,
    MoveFileDialogComponent,
    ConfirmDialogComponent,
    FinalizePoDialogComponent,
    AerqDefaultPoComponent,
    AerqDefaultFormatSpecificComponent,
    AboveDefaultFormatSpecificComponent,
    AboveDefaultSpecificComponent,
    DummyComponent,
    LogoAreaComponent,
    HeadlineSectionComponent,
    PlayerSelectAudioComponent,
    PlayerSelectTypeComponent,
    PlayerSelectTextComponent,
    PlayerSelectVideoComponent,
    VodWatermarkPoComponent,
    VodDefaultPoComponent,
    ProcessLinkComponent,
    DurationPipe,
    ProcessLinkComponent,
    ArchiveRestoreDialogComponent,
    DurationPipe,
    VodDemoPlayerPipe,
    S3LinkComponent,
    BasicPoitemInfoComponent,
    VodDefaultFormatSpecificComponent,
    AerqDefaultFormatSpecificComponent,
    AboveDefaultFormatSpecificComponent,
    AboveDefaultSpecificComponent,
    LanguageSelectComponent,
    LangNamePipe,
    OrganizationSwitcherComponent,
    ClTabsComponent,
    ClTabComponent,
    ContainsPipe,
    FooterComponent,
    AirlineSelectComponent,
    FilterSectionComponent,
    FolderAssetViewComponent,
    FolderListingComponent,
    DeleteDialogComponent,
    UndeleteDialogComponent,
    FileFolderLinkPipe,
    MezzPreviewPlayerComponent,
    TrackSelectionComponent,
    BasicPoitemVodInfoComponent,
    VodWatermarkFormatSpecificComponent,
    AboveDefaultSpecificComponent,
    PoItemLineComponent,
    PoItemDetailsComponent,
    HomeComponent,

  ],
  providers,
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    AppRoutingModule,
    ApolloModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiurl],
        sendAccessToken: false,
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: ngsw.enabled}),
    DragDropModule,
    QRCodeModule,
    LayoutModule,
    NgIdleModule.forRoot(),
    NgxJsonViewerModule,
    NgxGalleryModule, ScrollingModule,
    PortalModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {

  }
}
