import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';

@Component({
  selector: 'app-above-default-specific',
  templateUrl: './above-default-specific.component.html',
  styleUrls: ['./above-default-specific.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => AboveDefaultSpecificComponent),
  },
    {
      provide: NG_VALIDATORS,
      useExisting: AboveDefaultSpecificComponent,
      multi: true
    }]
})
export class AboveDefaultSpecificComponent implements OnInit, ControlValueAccessor, OnChanges {
  static DEFAULTS = {
    aspect_ratio: 'pass-through',
    watermark: false,
    metadata_reference: '',
  };

  @Input()
  ife_format: {
    item_id: string,
    item_text: string,
    watermark: boolean,
    filename: string,
    metadata_source_type: null,
    wrap_up_workflow: null,
    require_metadata_reference: boolean,
    vtk_template: string,
    po_destinations: any[]
  };

  form: FormGroup;
  private changed;
  private touched;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('aaaa')
    if (this.ife_format && this.form) {
      if (!this.ife_format.watermark) {
        this.form.controls['watermark'].setValue(false)
        this.form.controls['watermark'].disable()
      } else {
        this.form.controls['watermark'].enable()
      }
      if (this.ife_format.require_metadata_reference) {
        this.form.controls['metadata_reference'].clearValidators()
        this.form.controls['metadata_reference'].setValidators([Validators.required])
      } else {
        this.form.controls['metadata_reference'].clearValidators()
      }
      this.form.controls['watermark'].updateValueAndValidity();
      this.form.controls['metadata_reference'].updateValueAndValidity();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable()
    } else {
      this.form.enable()
    }
  }


  writeValue(value) {
    this.form.patchValue(value, {emitEvent: false})
  }

  registerOnChange(fn) {
    this.changed = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      watermark: [false, [Validators.nullValidator]],
      aspect_ratio: ['pass-through', [Validators.required]],
      metadata_reference: ['', [this.ife_format?.require_metadata_reference ? Validators.required : Validators.nullValidator]],
    })
    this.form.valueChanges.subscribe((val) => {
      if (this.touched) {
        this.touched();
      }
      if (this.changed) {
        this.changed(val);
      }
    });

  }

  validate({value}: FormControl) {
    const isNotValid = !this.form.valid
    return isNotValid && {
      invalid: true
    }
  }


}
