export const environment = {
  production: false,
  env_name: 'stag',
  accountId: '561405691527',

  castlabs_idp_issuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_Yuu8o8Ilg',
  castlabs_idp_app_client_id: '2o4rt792ffknurp5qb5uv46s3h',
  castlabs_idp_logout_url: 'https://login.test.cs.castlabs.com/logout?client_id={{client_id}}&logout_uri=\' + window.location.origin + \'/',

  portal_idp_issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_k6qDbT8EU',
  portal_idp_app_client_id: '21kc8411mskbamr85tkhshtar0',
  portal_idp_logout_url: 'https://portal-login.content-stag.castlabs.com/logout',


  aerq_idp_issuer: 'https://usermanager.ct.staging.ifesw.com/auth/realms/controltower',
  aerq_idp_app_client_id: 'castlabs-red-portal',
  aerq_idp_app_client_secret: 'VXl2tln4DKxBGaGkyTlONlZsR4NvAzJ1',

  touch_idp_issuer: 'https://id-sandbox.mediacenter.aero',
  touch_idp_app_client_id: 'CastlabsIdProvider',
  touch_idp_app_client_secret: '8MYm0zV5GajgfQirIypuvtz2tjB0vJaL',

  above_idp_issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_NdTjueRpE',
  above_idp_app_client_id: '3tu1fdceo0dff13umce21f9ab9',

  apiurl: 'https://portal.content-stag.castlabs.com/current',
  uploaderurl: 'https://up.content-stag.castlabs.com',

  VTK: 'https://vtks.castlabs.com',
  Portal: 'https://portal.content-stag.castlabs.com',

  region: 'us-east-1',
  graphql: 'https://repository.content-stag.castlabs.com/graphql',
  workflow_graphql: 'https://workflow.content-stag.castlabs.com/graphql',

  sentry_dsn: "https://6159d540586d4d698519d4a62a2fd6f2@o457257.ingest.sentry.io/5509849",
  title: 'castLabs Content Portal (stag)',

  delta_dfp_destination: 'demo_aspera',
};
