import {Component, Inject, OnInit} from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {Apollo} from 'apollo-angular';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  performing = false

  constructor(public dialogRef: DialogRef<string>,
              @Inject(DIALOG_DATA) public data: { message: string, actionName: string, action: () => void}) {
  }

  ngOnInit(): void {
  }

  performAction() {
    this.data.action();
    this.dialogRef.close();
  }
}
