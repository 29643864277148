import {Component, Inject, OnInit} from '@angular/core';
import {Folder, Mutation} from '../../../types';
import {Clipboard} from '@angular/cdk/clipboard';
import {Apollo} from 'apollo-angular';
import {CREATE_UPLOAD_TICKET} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-generate-upload-link-dialog',
  templateUrl: './generate-upload-link-dialog.component.html',
  styleUrls: ['./generate-upload-link-dialog.component.scss']
})
export class GenerateUploadLinkComponent implements OnInit {
  message: string;
  feedback: string;
  folder: Folder;
  uploadurl: string = null;

  constructor(private apollo: Apollo,
              public dialogRef: DialogRef<GenerateUploadLinkComponent>,
              private clipboard: Clipboard,
              @Inject(DIALOG_DATA) public data: { folder: Folder }) {
    this.folder = this.data.folder;
    const components = this.data.folder.id.split('/')
    components.pop()
    this.message = components.pop()
  }

  genLink() {
    this.feedback = undefined;

    this.apollo.mutate<Mutation>({
      mutation: CREATE_UPLOAD_TICKET,
      variables: {
        folder_id: this.folder.id,
        message: this.message
      },
      fetchPolicy: 'network-only'
    }).subscribe(d => {
      this.uploadurl = d.data.createUploadTicket.url
    });
  }

  copyClipboard() {
    this.clipboard.copy(this.uploadurl)
  }

  ngOnInit() {
  }

}
