import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Fullscreen} from '../../previews/player/fullscreen';
import {MatMenuTrigger} from '@angular/material/menu';
import {ActivatedRoute} from '@angular/router';
import {BannerService} from '../../../banner/banner.service';
import {HttpClient} from '@angular/common/http';
import {forkJoin, from, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {FileTrack} from './folder-asset-view.component';

declare const clpp: any;


@Component({
  selector: 'app-mezz-preview-player',
  templateUrl: './mezz-preview-player.component.html',
  styleUrls: ['./mezz-preview-player.component.scss']
})
export class MezzPreviewPlayerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() manifest: string;
  @Input() m3u8: string;
  @Input() drmtodayToken: string;
  @Input() accessToken: string;
  @Input() abToken: string;
  @Input() selectedVideoFileTrack!: FileTrack;
  @Input() selectedAudioFileTrack!: FileTrack;
  @Input() selectedTextFileTrack!: FileTrack;

  trackSources: {
    sourceTrackId: number,
    representationId: string,
    sourceFilename: string,
  }[] = []

  player: any;
  error: any;
  airline: string;
  playerState = {
    mouseMoved: false,
    isPlaying: false,
    progress: 0,
    duration: 0,
    isSeeking: false,
    isSelectingTrack: false,
    isFullscreen: false,
    isLoading: true,
    isInitializing: true
  }
  fullscreenManager: Fullscreen;

  videoRenditions: any[] = [];
  audioRenditions: any[] = [];
  textRenditions: any[] = [];

  progressBarContainer: HTMLElement;

  token: any;

  streamType = ''

  mouseMoveTimeout = null;
  @ViewChild(MatMenuTrigger) notificationMenuBtn: MatMenuTrigger;

  constructor(
    private route: ActivatedRoute,
    private banner: BannerService,
    private cdRef: ChangeDetectorRef,
    private httpClient: HttpClient,
  ) {
    this.airline = this.route.snapshot.params['airline'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedVideoFileTrack'] && this.selectedVideoFileTrack) {
      this.setVideoRendition(this.selectedVideoFileTrack)
    }
    if (changes['selectedAudioFileTrack'] && this.selectedAudioFileTrack) {
      this.setAudioRendition(this.selectedAudioFileTrack)
    }
    if (changes['selectedTextFileTrack'] && this.selectedTextFileTrack) {
      this.setTextRendition(this.selectedTextFileTrack)
    } else {
      this.player.getTrackManager().setTextRendition(null, true);
    }
  }

  startMouseMovedTimer = () => {
    this.playerState.mouseMoved = true;
    this.cdRef.detectChanges();
    clearTimeout(this.mouseMoveTimeout);
    this.mouseMoveTimeout = setTimeout(() => {
      this.playerState.mouseMoved = false;
      this.cdRef.detectChanges()
    }, 2000);
  }

  initFullscreen = () => {
    const playerContainer = document.getElementById('player-container');
    this.fullscreenManager = new Fullscreen(
      playerContainer,
      document,
      () => {
        this.playerState.isFullscreen = this.fullscreenManager.fullscreenEnabled();
      },
    );
  }


  loadPlayer() {

    const fpObs = from(navigator.requestMediaKeySystemAccess('com.apple.fps.1_0', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const prObs = from(navigator.requestMediaKeySystemAccess('com.microsoft.playready', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const wvObs = from(navigator.requestMediaKeySystemAccess('com.widevine.alpha', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const prRobustnessObs = from(navigator.requestMediaKeySystemAccess('com.microsoft.playready', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'robustness': '2000',
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'robustness': '2000',
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))

    const wvRobustnessObs = from(navigator.requestMediaKeySystemAccess('com.widevine.alpha', [{
      'initDataTypes': ['cenc'],
      'audioCapabilities': [{
        'robustness': 'SW_SECURE_CRYPTO',
        'contentType': 'audio/mp4;codecs="mp4a.40.2"'
      }],
      'videoCapabilities': [{
        'robustness': 'SW_SECURE_CRYPTO',
        'contentType': 'video/mp4;codecs="avc1.42E01E"'
      }]
    }])).pipe(map(() => true), catchError(() => of(false)))
    const obs = forkJoin([prObs, wvObs, prRobustnessObs, wvRobustnessObs, fpObs]).pipe(shareReplay(1))
    obs.subscribe(
      v => {
        if (!(v[2] || v[3] || v[4])) {
          this.banner.open('This browser neither supports PlayReady SL 2000, Widevine SW_SECURE_CRYPTO nor Apple FairPlay . Previews are disabled.', ['Close'])
        }
        if (this.abToken) {
          const networkEngine = this.player.getNetworkEngine();
          const that = this;
          networkEngine.addRequestModifier(function (request) {
            if (request.type === clpp.net.RequestType.MANIFEST) {
              request.headers['token'] = that.abToken;
            } else if (request.type === clpp.net.RequestType.SEGMENT) {
              request.headers['token'] = that.abToken;
            }
          });
        }
        let manifest = '';
        let manifestType = null;
        let preferredDrmSystem = null;

        if (this.m3u8 && this.manifest) {
          if (v[4]) {
            manifest = this.m3u8
            this.player.use(clpp.hls.HlsComponent);
            preferredDrmSystem = clpp.drm.KeySystem.FAIRPLAY
            manifestType = clpp.Type.HLS
          } else {
            manifest = this.manifest
            preferredDrmSystem = clpp.drm.KeySystem.WIDEVINE
            this.player.use(clpp.dash.DashComponent);
            manifestType = clpp.Type.DASH
          }
        } else if (this.m3u8 && !this.manifest) {
          if (v[4]) {
            preferredDrmSystem = clpp.drm.KeySystem.FAIRPLAY
            manifestType = clpp.Type.HLS
            manifest = this.m3u8
            this.player.use(clpp.hls.HlsComponent);
          } else {
            this.banner.open('Preview is only available as HLS. Please preview with a Safari browser', ['Close'])
          }
        } else if (!this.m3u8 && this.manifest) {
          if (v[2] || v[3]) {
            manifest = this.manifest
            preferredDrmSystem = clpp.drm.KeySystem.WIDEVINE
            this.player.use(clpp.dash.DashComponent);
            manifestType = clpp.Type.DASH
          } else {
            this.banner.open('Preview is only available as DASH. Please preview with a Chrome or Edge browser', ['Close'])
          }
        }

        console.log(manifest)
        const playerInit = {
          source: {
            url: manifest,
            type: manifestType
          }
        }
        if (this.drmtodayToken) {
          const payload = JSON.parse(atob(this.drmtodayToken.split('.')[1]))
          const optData = JSON.parse(payload['optData'])
          playerInit['drm'] = {
            env: 'DRMtoday',
            preferredDrmSystem: preferredDrmSystem,
            customData: {
              userId: optData['userId'],
              sessionId: optData['sessionId'],
              merchant: optData['merchant'],
              authToken: this.drmtodayToken
            }
          }
        }
        this.player.load(playerInit);
      })
    return obs

  }

  ngOnInit() {
    console.log('sdgdf')
    this.httpClient.get<any[]>(this.manifest.replace('Manifest.mpd', 'metadata.json')).subscribe(metadata => {
      for (const key in metadata) {
        if (metadata.hasOwnProperty(key)) {
          this.trackSources.push({
            representationId: metadata[key]['representation id'],
            sourceTrackId: metadata[key]['source track_id'],
            sourceFilename: metadata[key]['source file name'],
          })
        }
      }
    })

    const prestoPlayerScripts = [
      'assets/clpp-6.2.5/cl.core.min.js',
      'assets/clpp-6.2.5/cl.mse.min.js',
      'assets/clpp-6.2.5/cl.dash.min.js',
      'assets/clpp-6.2.5/cl.hls.min.js',
      'assets/clpp-6.2.5/cl.htmlcue.min.js',
      'assets/clpp-6.2.5/cl.vtt.min.js',
    ];
    const loadScript = (src) => new Promise(
      (resolve) => {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = src;
        scriptElement.onload = () => {
          resolve(true);
        };
        document.head.appendChild(scriptElement);
      }
    );
    let promise = loadScript(prestoPlayerScripts[0]);
    prestoPlayerScripts.slice(1).forEach(src => {
      promise = promise.then(() => loadScript(src));
    });
    promise.then(() => {
      const playerContainer = document.getElementById('player-container');

      this.player = new clpp.Player(
        'player',
        {
          autoplay: false,
          muted: false,
          license: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1cmxzIjpbImh0dHBzOi8vY29udGVudC5jYXN0bGFicy5jb20iLCJodHRwczovL2NvbnRlbnQtc3RhZy5jYXN0bGFicy5jb20iXSwidHlwZSI6IldlYiIsImtpZCI6MjkxMSwiaWx2IjpmYWxzZX0.oPAnJkScgbH6vOomr-tiJS54gMZSyjOHI23nVSppwSeFcdhxT-rbPtgwyZk9Jek9vx_RTZlF5uIx5y5yj8eJINaoWicDt42tIvjLcHz-5yUmaDfb3lPk35Ygj0fsse9uQlTgbTz5X_eAqihx5Ogz4rYccovz807iPgFY8btsi-0gISmVUVeJI5cwLewxyuD91LVjvrofqY8Jaejy86gNk69r2v90Dmp5O5CSyb8Xozq5FZmb6S7kmDacMoxmekFBQxhIgInnLjGHL1l3mpn2bhLoV7z8okkUyg95nbeScsY-qAFNcRUV5cN5sczeFz-owOe9aPHGUuMtHZzPcYyk_Q',
          textstyle: {
            backgroundColor: 'rgba(0,0,0,0)',
            fontFamily: 'Open Sans',
            fontSize: '2em',
            edgeColor: 'dark-gray',
            edgeType: clpp.text.EdgeType.UNIFORM
          }
        },
        {
          disableContainer: true,
          containerEl: playerContainer
        },
      );
      this.player.use(clpp.htmlcue.HtmlCueComponent);


      this.player.on('error', err => {
        this.banner.open(err.detail.message, ['Close'])
        console.log(err)
      })

      this.progressBarContainer = document.getElementById('progress-bar-container');
      this.initFullscreen();


      playerContainer.addEventListener('mousemove', () => {
        this.startMouseMovedTimer()
      });

      this.player.use(clpp.htmlcue.HtmlCueComponent);
      this.player.use(clpp.vtt.VttComponent);
      this.player.on('timeupdate', () => {
        if (!this.playerState.isSeeking) {
          this.playerState.progress = this.player.getPosition();
        }
      });
      this.player.on(clpp.events.LOADEDMETADATA, () => {
        this.playerState.duration = this.player.getDuration();
        this.playerState.isLoading = false;
        this.playerState.isInitializing = false;
        this.player.getTextDisplayer().setFontColor('rgba(235, 235, 235, 0.8)')
        this.cdRef.detectChanges()
        this.startMouseMovedTimer(); // display controls!
        this.setVideoRendition(this.selectedVideoFileTrack);
        this.setAudioRendition(this.selectedAudioFileTrack);
        this.setTextRendition(this.selectedTextFileTrack);
      });
      this.player.on(clpp.events.SEEKING, () => {
        this.playerState.isLoading = true;
      })
      this.player.on(clpp.events.SEEKED, () => {
        this.playerState.isLoading = false;
        this.cdRef.detectChanges()
      })
      this.player.on(clpp.events.ERROR, event => {
        this.error = event.detail;
      });

      this.player.on(clpp.events.TRACKS_ADDED, () => {
        const trackManager = this.player.getTrackManager();
        this.videoRenditions = trackManager.getVideoTracks().map(
          videoTrack => videoTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
        this.audioRenditions = trackManager.getAudioTracks().map(
          audioTrack => audioTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
        this.textRenditions = trackManager.getTextTracks().map(
          textTrack => textTrack.renditions).reduce((prev, curr) => [...prev, ...curr], [])
      });

      this.loadPlayer()
    });
  };

  ngOnDestroy(): void {
    this.player.destroy();
  }

  play() {
    if (this.playerState.isLoading) {
      return;
    }
    this.player.play().then(
      () => {
        console.log('playing')
        this.playerState.isPlaying = true;
      }
    );
  }

  pause() {
    if (this.playerState.isLoading) {
      return;
    }
    this.player.pause().then(
      () => {
        console.log('paused')
        this.playerState.isPlaying = false;
      }
    );
  }

  isVisible() {
    const {isSeeking, mouseMoved, isSelectingTrack, isInitializing} = this.playerState;
    return (isSeeking || mouseMoved || isSelectingTrack) && !isInitializing;
  }

  getProgressPercent() {
    const duration = Math.max(this.playerState.duration, 0.001);
    return (this.playerState.progress / duration * 100).toFixed(1) + '%';
  }

  getFormattedProgress() {
    const {progress, duration} = this.playerState;
    const padZero = num => num.toString().padStart(2, '0');
    const hours = Math.floor(progress / 3600);
    const minutes = Math.floor((progress - 3600 * hours) / 60);
    const seconds = Math.floor(progress - 3600 * hours - 60 * minutes);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  getFormattedMediaRunTime() {
    const {progress, duration} = this.playerState;
    const padZero = num => num.toString().padStart(2, '0');
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - 3600 * hours) / 60);
    const seconds = Math.floor(duration - 3600 * hours - 60 * minutes);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }


  startSeek(event) {
    const updateProgressOnSeek = (updateProgressOnSeekEvent) => {
      const boundingRect = this.progressBarContainer.getBoundingClientRect();
      this.playerState.progress = this.playerState.duration * Math.max(
        Math.min((updateProgressOnSeekEvent.pageX - boundingRect.left) / boundingRect.width, 1), 0);
    };
    const stopSeek = () => {
      this.playerState.isSeeking = false;
      document.removeEventListener('mousemove', updateProgressOnSeek)
      document.removeEventListener('mouseup', stopSeek);
      this.player.seek(this.playerState.progress);
    };
    document.addEventListener('mouseup', stopSeek);
    document.addEventListener('mousemove', updateProgressOnSeek);
    this.playerState.isSeeking = true;
    updateProgressOnSeek(event);
  }

  setVideoRendition(f: FileTrack) {
    const renditionId = this.trackSources.find(
      r => r.sourceTrackId === f.track.index &&
        r.sourceFilename === f.file.name)['representationId']
    const rendition = this.videoRenditions.find(r => r.originalId === renditionId)
    console.log('rendition', rendition)
    console.log('selectedVideoFileTrack', f)
    console.log('renditionId', renditionId);
    this.player.getTrackManager().setVideoRendition(rendition, true);
  }

  setAudioRendition(f: FileTrack) {
    const renditionId = this.trackSources.find(
      r => r.sourceTrackId === f.track.index &&
        r.sourceFilename === f.file.name)['representationId']
    const rendition = this.audioRenditions.find(r => r.originalId === renditionId)
    console.log('rendition', rendition)
    console.log('selectedAudioFileTrack', f)
    console.log('renditionId', renditionId)
    this.player.getTrackManager().setAudioRendition(rendition, true);
  }

  setTextRendition(f: FileTrack) {
    if (!f) {
      this.player.getTrackManager().setTextRendition(null, true);
    } else {
      const renditionId = this.trackSources.find(
        r => r.sourceTrackId === f.track.index &&
          r.sourceFilename === f.file.name)['representationId']
      const rendition = this.textRenditions.find(r => r.originalId === renditionId)
      console.log('rendition', rendition)
      console.log('selectedTextFileTrack', f)
      console.log('renditionId', renditionId)
      this.player.getTrackManager().setTextRendition(rendition, true);
    }
  }


}
