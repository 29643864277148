import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Mutation as WorkflowMutation, PoItem, ProcessStateEnum} from '../../../types-workflow';
import {PO_DESTINATIONS} from '../../../target_systems';
import {DownloadEncodeDialogComponent} from '../download-encode-dialog/download-encode-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {Apollo} from 'apollo-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BannerService} from '../../../banner/banner.service';
import {RESTART_WORKFLOW} from '../../../queries';
import {ConfirmDialogComponent} from '../../../confirm-dialog/confirm-dialog.component';
import {waitForProcess} from '../../utils';
import gql from 'graphql-tag';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-po-item-line]',
  templateUrl: './po-item-line.component.html',
  styleUrls: ['./po-item-line.component.scss']
})
export class PoItemLineComponent implements OnInit {

  protected open = false

  @Input()
  poItem: PoItem;

  @Output()
  toggleDetails = new EventEmitter<boolean>();


  protected readonly PO_DESTINATIONS = PO_DESTINATIONS;
  publish_single_po_inflight = false;
  restart_single_po_inflight = false;

  @Input() displayMetadata!: boolean ;

  constructor(
    private dialog: Dialog,
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private banner: BannerService) {
  }

  toggle() {
    console.log('toggle', this.open)
    this.open = !this.open
    this.toggleDetails.emit(this.open)
  }

  ngOnInit(): void {
  }

  openDlDialog() {
    this.dialog.open(DownloadEncodeDialogComponent, {
      data: {po_name: this.poItem.po.po_name, po_item_id: this.poItem.po_item_id},
    })
  }


  publish_single_po() {
    const action = () => {
      this.publish_single_po_inflight = true;
      this.apollo.use('workflow').mutate<WorkflowMutation>({
        mutation: gql`mutation publish_single_po {
        publishPoItem(input: { po_name: "${this.poItem.po.po_name}", po_item_id: "${this.poItem.po_item_id}" }) {
          id
          state
          message
          start_date
          end_date
        }
    }`
      }).subscribe(res => {
        this.publish_single_po_inflight = false;
        const client = this.apollo.use('workflow').client;
        const cache = client.cache;
        cache.modify({
          id: cache.identify({__typename: 'POItem', id: this.poItem.id}),
          fields: {
            publish_process(existingPublishProcess, {toReference}) {
              return toReference(res.data.publishPoItem);
            },
          },
        });

        waitForProcess(this.apollo.use('workflow'), res.data.publishPoItem.id).subscribe({
          next: (res) => {
            if (res.data.process.state === 'FAILED') {
              this.banner.open('Publish of ' + this.poItem.po_item_id + ' failed.', ['Close']);
            }
            if (res.data.process.state === 'SUCCESS') {
              this.snackBar.open('Publish of ' + this.poItem.po_item_id + ' completed', null, {duration: 5000});
            }
          },
          error: (error) => {
            this.banner.open(error, ['Close']);
          }
        })
      })
    }
    if (this.poItem.publish_process &&
      (this.poItem.publish_process.state === ProcessStateEnum.InProgress ||
        this.poItem.publish_process.state === ProcessStateEnum.Success)) {
      let secondLine = ''
      if (this.poItem.publish_process.state === ProcessStateEnum.InProgress) {
        secondLine = 'This will restart an already running publish process.'
      }
      if (this.poItem.publish_process.state === ProcessStateEnum.Success) {
        secondLine = 'This will restart a completed publish process.'
      }
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Do you really want to publish ${this.poItem.po_item_id}?<br/>${secondLine}`,
          actionName: 'Publish',
          action: action
        }
      });
    } else {
      action();
    }

  }

  restartWorkflow() {
    const action = () => {
      this.restart_single_po_inflight = true;
      this.apollo.use('workflow').mutate<WorkflowMutation>({
        mutation: RESTART_WORKFLOW,
        variables: {
          po_name: this.poItem.po.po_name,
          po_item_id: this.poItem.po_item_id
        }
      }).subscribe((res) => {
        const client = this.apollo.use('workflow').client;
        const cache = client.cache;
        // the workflow process takes a while to update the POItem dynamo db entry so we reset the object in the cache
        cache.modify({
          id: cache.identify({__typename: 'POItem', id: this.poItem.id}),
          fields: {
            tracks() { return []},
            checkpoint_content_uploaded() { return false},
            checkpoint_content_complete() { return false},
            checkpoint_encodes_done() { return false},
            vtk_jobs() { return []},
            preview() { return null},
            workflow_process(existingWorkflowProcess, {toReference}) {
              console.log('update workflow_process', res.data.restartPoItem.id)
              return toReference(res.data.restartPoItem);
            },
          },
        });


        this.restart_single_po_inflight = false;
        this.snackBar.open(`Restarted ${this.poItem.po_item_id}.`, null, {duration: 5000})
        waitForProcess(this.apollo.use('workflow'), res.data.restartPoItem.id).subscribe({
          next: (res) => {
            if (res.data.process.state === 'FAILED') {
              this.banner.open('Reencode of ' + this.poItem.po_item_id + ' failed.', ['Close']);
            }
            if (res.data.process.state === 'SUCCESS') {
              this.snackBar.open('Reencode of ' + this.poItem.po_item_id + ' completed', null, {duration: 5000});
            }
          },
          error: (error) => {
            this.banner.open(error, ['Close']);
          }
        })

      })
    }

    if (this.poItem.workflow_process &&
      (this.poItem.workflow_process.state === ProcessStateEnum.InProgress ||
        this.poItem.workflow_process.state === ProcessStateEnum.Success)) {
      let secondLine = ''
      if (this.poItem.workflow_process.state === ProcessStateEnum.InProgress) {
        secondLine = 'This will restart an already running encode process.'
      }
      if (this.poItem.workflow_process.state === ProcessStateEnum.Success) {
        secondLine = 'This will restart a completed encode process.'
      }
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: `Do you really want to restart ${this.poItem.po_item_id}?<br/>${secondLine}`,
          actionName: 'Restart',
          action: action
        }
      });
    } else {
      action();
    }

  }
}
