<div class="row cl-spacing-small" [formGroup]="form" *ngIf="ife_format">
  <div [class.col-6]="!ife_format.require_metadata_reference" [class.col-4]="ife_format.require_metadata_reference">
    <label for="aspect_ratio" class="form-label">Aspect Ratio</label>
    <select id="aspect_ratio" class="form-select" formControlName="aspect_ratio">
      <option value="ar_16x9">Force 16/9
      </option>
      <option value="ar_4x3">Force 4/3
      </option>
      <option value="pass-through">Pass-through
      </option>
    </select>
  </div>
  <div [class.col-6]="!ife_format.require_metadata_reference" [class.col-4]="ife_format.require_metadata_reference">
    <label for="watermark" class="form-label">Watermark</label>
    <select id="watermark" class="form-select" formControlName="watermark">
      <option [value]="true">Insert forensic watermark (do not use)
      </option>
      <option [value]="false">No watermark
      </option>
    </select>
    <p *ngIf="!form?.controls['watermark'].errors" class="valid-feedback">Insert or omit forensic watermark.</p>

  </div>

  <div *ngIf="ife_format.require_metadata_reference" class="col-4">
    <label for="metadata_reference" class="form-label">Metadata Reference<span class="required">*</span></label>
    <input id="metadata_reference" type="text" formControlName="metadata_reference">
    <p *ngIf="form?.controls['metadata_reference'].errors?.required" class="invalid-feedback">This field is
      required.</p>
    <p *ngIf="!form?.controls['metadata_reference'].errors" class="valid-feedback">A format specific reference to the
      metadata.</p>
  </div>
</div>
