import {Component, Inject, Input, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {mergeMap, skipWhile, take} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BannerService} from '../../../banner/banner.service';
import {Apollo} from 'apollo-angular';
import {Airline, CodecType, Mutation, ProcessStateEnum, Query} from '../../../types-workflow';

import {GET_PROCESS, START_WORKFLOW_DEFAULT} from '../../../queries';
import {forkJoin, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ALL_SYSTEMS, DELTA_SYSTEMS, IfeFormat, PoDestination, THALES_SYSTEMS} from '../../../target_systems';
import {Po2024Service} from '../../po-2024.service';
import {BasicPoitemInfoComponent} from '../basics-poitem-info/basic-poitem-info.component';
import {TrackSelectionComponent} from '../tracks-selection/track-selection.component';
import {clean_datetime, shortenJanusOrg} from '../../utils';
import {AboveDefaultFormatSpecificComponent} from '../above-default-format-specific/above-default-format-specific.component';
import {AboveDefaultSpecificComponent} from '../above-default-specific/above-default-specific.component';
import {Organization} from '../../../types';
import {OAuthService} from 'angular-oauth2-oidc';


@Component({
  selector: 'app-above-default-po',
  templateUrl: './above-default-po.component.html',
  styleUrls: ['./above-default-po.component.css'],
})
export class AboveDefaultPoComponent implements OnInit, OnDestroy {
  @Input()
  organization: Organization;

  @Input()
  airlines: Airline[] = [];

  availableSystems = []

  ifeFormats: IfeFormat[] = []
  poDestinations: PoDestination[] = []
  poFormGroup: FormGroup;
  ifeFormat: IfeFormat;

  subscriptions: Subscription[] = [];

  constructor(
    private apollo: Apollo,
    public snackBar: MatSnackBar,
    @Inject('above_default') protected poService: Po2024Service,
    private formBuilder: FormBuilder,
    private banner: BannerService,
    private router: Router,
    private oAuthService: OAuthService,
    private activatedRoute: ActivatedRoute,
  ) {



    if (this.activatedRoute.snapshot.params['organization_id'] === 'thales') {
      // thales organisation should only see thales and delta systems
      this.availableSystems = [].concat(DELTA_SYSTEMS, THALES_SYSTEMS);
    } else {
      this.availableSystems = ALL_SYSTEMS;
    }


    this.poFormGroup = formBuilder.group({
      po_number: [clean_datetime(new Date()), Validators.required],
      airline: [null, Validators.required],
      po_destination: [null],
      auto_publish: [false],
      due_date: ['now'],
      poItems: formBuilder.array([], Validators.required),
      date_created: [null],
      email_notification: [null, [Validators.required, Validators.email]],
      ife: [null, Validators.required],
      ife_format: [null, Validators.required],
      westent_cycle_name: [null, Validators.nullValidator],

    });
    this.poFormGroup.controls['airline'].valueChanges.subscribe(value => {
      console.log('Airline changed', value)
    })
    this.poFormGroup.controls['ife'].valueChanges.subscribe(value => {
      this.ifeFormats = this.availableSystems.find(as => as.item_id === value).formats;
      this.poFormGroup.controls['ife_format'].setValue(this.ifeFormats[0].item_id);
    })
    this.poFormGroup.controls['ife_format'].valueChanges.subscribe(value => {
      this.ifeFormat = this.ifeFormats.find(f => f.item_id === value)
      this.poDestinations = this.ifeFormat.po_destinations
      this.poFormGroup.controls['po_destination'].setValue(this.poDestinations[0].destination_id);
    })
    // todo retrieve email from user profile
    this.poFormGroup.controls['email_notification'].setValue(oAuthService.getIdentityClaims()['email']);

    this.poFormGroup.controls['po_destination'].valueChanges.subscribe(value => {
      if (value === 'west_entertainment') {
        this.poFormGroup.controls['westent_cycle_name'].clearValidators();
        this.poFormGroup.controls['westent_cycle_name'].setValidators([Validators.required]);
      } else {
        this.poFormGroup.controls['westent_cycle_name'].clearValidators();
      }
      this.poFormGroup.controls['westent_cycle_name'].updateValueAndValidity();
    })

    this.poService.getFormState().pipe(take(1)).subscribe(savedForm => {
      if (savedForm) {
        savedForm.poItems.forEach(poItem => (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(poItem)));
        this.poFormGroup.setValue(savedForm)
      }
    });
    this.poService.poItemToAdd$.subscribe(data => {
      if (data) {
        (this.poFormGroup.controls.poItems as FormArray).push(this.formBuilder.group(
          Object.assign(
            {},
            {
              basics: BasicPoitemInfoComponent.DEFAULTS,
              tracks: TrackSelectionComponent.DEFAULTS,
              above_specific: AboveDefaultSpecificComponent.DEFAULTS,
              format_specific_data: AboveDefaultFormatSpecificComponent.DEFAULTS
            },
            data)))
      }
    });
    this.subscriptions.push(this.poService.poItemToRemove$.subscribe(id => {
      if (id) {
        const index = (this.poFormGroup.controls.poItems as FormArray).controls.findIndex(poItem => poItem.get('id').value === id);
        (this.poFormGroup.controls.poItems as FormArray).removeAt(index);
      }
    }));
  }

  ngOnInit() {
    if (this.airlines.length === 1) {
      this.poFormGroup.controls['airline'].setValue(this.airlines[0].iata_code);
    }
  }

  compare_item_id(o1: any, o2: any) {
    return o1?.item_id === o2?.item_id;
  }

  compare_destination_id(o1: any, o2: any) {
    return o1?.destination_id === o2?.destination_id;
  }

  poItemTrackBy: TrackByFunction<FormGroup> = (index: number, poitem: FormGroup) => {
    return poitem.get('id').value;
  }


  submit_po() {
    if (!this.poFormGroup.valid) {
      console.log(this.poFormGroup)
      alert('Invalid')
      this.poFormGroup.markAllAsTouched();
      return
    }
    const workflows = []
    const emails = this.poFormGroup.value.email_notification.split(',').map(e => e.trim()).filter(e => e !== '')

    for (const source of this.poFormGroup.value.poItems) {
      console.log(this.ifeFormat.vtk_template)
      workflows.push(this.apollo.use('workflow').mutate<Mutation>({
        mutation: START_WORKFLOW_DEFAULT,
        variables: {
          email_notification: emails,
          auto_publish: this.poFormGroup.value.auto_publish,
          aspect_ratio: source.above_specific.aspect_ratio,
          filename: source.basics.filename.trim(),
          metadata_source: this.ifeFormat.metadata_source_type ?
            (this.ifeFormat.metadata_source_type + '://' + source.above_specific.metadata_reference) : null,
          wrap_up_workflow: this.ifeFormat.wrap_up_workflow,
          content_identifier: `input_brefix://${source.basics.input_brefix}`,
          po_item_id: source.basics.po_item_id.trim(),
          po_name: `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number.trim()}`,
          po_destination: this.poFormGroup.value.po_destination,
          vtk_template: this.ifeFormat.vtk_template,
          format_specific_data: JSON.stringify(source.format_specific_data),
          watermark: source.above_specific.watermark,
          tracks:
            ((this.ifeFormat.audio_only || false) ? [] : [{'codec_type': CodecType.Video}])
              .concat(
                source.tracks.audio.map(t => {
                  return {
                    'codec_type': CodecType.Audio,
                    'lang': t
                  }
                })
              ).concat(
              source.tracks.subtitles.map(t => {
                return {
                  'codec_type': CodecType.Subtitle,
                  'lang': t
                }
              })
            ).concat(
              source.tracks.cc.map(t => {
                return {
                  'codec_type': CodecType.Closedcaption,
                  'lang': t
                }
              })
            ).concat(
              ((this.ifeFormat.image_required || false) ? source.tracks.images.map(t => {
                return {
                  'codec_type': CodecType.Image,
                  'filename': t,
                }
              }) : []))
        }
      }).pipe(
        mergeMap(d => {
          const processId = d.data.start_workflow_default.id
          return this.apollo.use('workflow').watchQuery<Query>(
            {
              query: GET_PROCESS,
              pollInterval: 2000,
              variables: {
                id: processId
              }
            }
          ).valueChanges
        }),
        skipWhile(d => d.data.process.state === ProcessStateEnum.InProgress),
        take(1)
      ))
    }

    const sb_ref = this.snackBar.open('Workflows started.', `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
      {
        duration: 5000
      })

    sb_ref.onAction().subscribe(() => {
      this.router.navigate(
        ['/o', this.organization.id , 'workflows',
          this.poFormGroup.value.airline,
          `${this.poFormGroup.value.airline}_${this.poFormGroup.value.po_number}`])
    })
    forkJoin(workflows).subscribe(
      r => {
        sb_ref.dismiss();
        this.snackBar.open(`All workflows in ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number} have completed.`,
          `go to ${shortenJanusOrg(this.poFormGroup.value.airline)}_${this.poFormGroup.value.po_number}`,
          {
            duration: 5000
          }).onAction().subscribe(() => this.router.navigate(
          ['/workflows',
            this.poFormGroup.value.po_customer,
            `${this.poFormGroup.value.po_customer}_${this.poFormGroup.value.po_number}`]))
      },
      error => {
        this.banner.open('Workflow submission failed: ' + error, ['Close'])
      }
    )

  }

  get poItems() {
    return this.poFormGroup.get('poItems') as FormArray<FormGroup>;
  }

  get fields() {
    return this.poFormGroup.controls;
  }

  a(a: any) {
    console.log(a)
  }

  ngOnDestroy(): void {
    this.poService.setFormState(this.poFormGroup.value)
    this.subscriptions.forEach(s => s.unsubscribe())
  }

}
