import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, Subject} from 'rxjs';
import {File, Folder, Organization, Query, RestoreState, TrackType} from '../../../types';
import {debounceTime, map, shareReplay, startWith, take, tap} from 'rxjs/operators';
import {MkdirDialogComponent} from '../mkdir-dialog/mkdir-dialog.component';
import {Dialog} from '@angular/cdk/dialog';
import {GenerateUploadLinkComponent} from '../generate-upload-link-dialog/generate-upload-link-dialog.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {FILTER_DEBOUNCE_TIME} from '../../filter-section/filter-section.component';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {Apollo} from 'apollo-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BannerService} from '../../../banner/banner.service';
import {FILE_FOLDER_LIST_WITH_ARCHIVE, GET_FOLDER2} from '../../../queries';
import {MoveFileDialogComponent} from '../move-file-dialog/move-file-dialog.component';
import {RenameFileDialogComponent} from '../rename-file-dialog/rename-file-dialog.component';
import {ArchiveRestoreDialogComponent} from '../../archive-restore-dialog/archive-restore-dialog.component';
import {SideNavService} from '../../../services/side-nav.service';
import {DeleteDialogComponent} from '../../../delete-dialog/delete-dialog.component';
import {UndeleteDialogComponent} from '../../../undelete-dialog/undelete-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {PoMetaService} from '../../po-meta.service';
import {OrganizationService} from '../../../services/organization.service';

@Component({
  selector: 'app-folder-listing',
  templateUrl: './folder-listing.component.html',
  styleUrls: ['./folder-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('flyInAnimation', [
      transition(':leave', [
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
        style({transform: 'translateX(100%)'})
      ]),
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')
      ])

    ])
  ],
})
export class FolderListingComponent implements OnInit {
  @Input()
  organization: Organization;

  protected readonly RestoreState = RestoreState;
  selection = new SelectionModel<Folder | File>(true, [], true, (f1, f2) => f1.id === f2.id);
  folder$ = new BehaviorSubject<Folder>(null);

  items$ = this.folder$.pipe(
    map(f => {
        return {
          self: f,
          parent: f.parents.length > 0 ? f.parents.slice(-1)[0] : null,
          children: [
            ...f.folders,
            ...f.files]
        }
      }
    ),
    tap(items => {
      const refreshedSelection = items.children.filter(f => this.selection.selected.find(s => s.id === f.id));
      this.selection.clear();
      this.selection.select(...refreshedSelection);
      // clear/select due to https://github.com/angular/components/issues/27425
    })
  )


  protected reevaluateButtonsTrigger = new Subject<any>()


  protected no_deleted_files_selected$: Observable<boolean>;
  protected only_deleted_files_selected$: Observable<boolean>;
  protected only_archived_files_selected$: Observable<boolean>;
  protected only_one_folder_selected$: Observable<Folder>;
  protected allowSubmitOnAllFolder$: Observable<Folder[]>;

  protected indeterminate$: Observable<boolean>;
  protected checked$: Observable<boolean>;

  isSmall$: Observable<boolean>;

  sorters: BehaviorSubject<Array<{
    dir: 'up' | 'down' | null,
    order: Date,
    property: string,
  }>> = new BehaviorSubject([
    {dir: null, order: null, property: 'selected'},
    {dir: null, order: null, property: '__typename'},
    {dir: 'down', order: new Date(), property: 'name'},
    {dir: null, order: null, property: 'last_modified'},
    {dir: null, order: null, property: 'size'}
  ])


  protected items_sorted_and_filtered$: Observable<{
    parent: Folder,
    children: Array<Folder | File>
  }>;

  filters: FormGroup = new FormGroup({
    t: new FormControl<string>(''),
    d: new FormControl<boolean>(false),
  });

  @HostListener('window:keydown.escape', ['$event']) onKeydownHandler() {
    console.log(this.dialog.openDialogs.length)
    if (this.dialog.openDialogs.length === 0) {
      this.selection.clear()
    }
  }


  @Input()
  set folder(value: Folder) {
    this.folder$.next(value);
  }

  get folder() {
    return this.folder$.getValue();
  }


  constructor(
    private dialog: Dialog,
    private breakPointObserver: BreakpointObserver,
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private banner: BannerService,
    public sideNavService: SideNavService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private poMetaService: PoMetaService) {
    this.isSmall$ = this.breakPointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(result => result.matches),
        shareReplay()
      );
  }

  ngOnInit(): void {
    this.items_sorted_and_filtered$ = combineLatest(
      [combineLatest(
        [
          this.items$,
          this.filters.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME))
        ]).pipe(
        map(([items, filters]) => {
          return {
            self: items.self,
            parent: items.parent,
            children: items.children
              .filter(f => f.name.toLowerCase().includes((filters['t'] || '').toLowerCase()))
              .filter(f => f.__typename === 'Folder' || f.__typename === 'File' && (f.deleted && !(filters['d'] || false) || !f.deleted))
          }
        }),
        shareReplay(1)
      ), this.sorters]).pipe(
      map(([items, sorters]) => {
        function addLeadingZero(str) {
          const pattern = /S(\d+)E(\d+)/g;
          return str.replace(pattern, (match, p1, p2) => {
            return `S${p1.padStart(2, '0')}E${p2.padStart(2, '0')}`;
          });
        }

        const applicableSorters = sorters.filter(v => v.dir !== null).sort((a, b) => a.order.getTime() - b.order.getTime())
        for (const applicableSorter of applicableSorters) {
          items.children = items.children.sort((a, b) => {
            if (applicableSorter.property === '__typename' && a.__typename === 'File' && a.deleted) {
              if (applicableSorter.dir === 'up') {
                return -1
              } else {
                return +1
              }
            }
            if (applicableSorter.property === '__typename' && b.__typename === 'File' && b.deleted) {
              if (applicableSorter.dir === 'up') {
                return +1
              } else {
                return -1
              }
            }
            if (a[applicableSorter.property] === b[applicableSorter.property]) {
              return 0
            }
            if (a[applicableSorter.property] === undefined) {
              return +1
            }
            if (b[applicableSorter.property] === undefined) {
              return -1
            }

            if (typeof a[applicableSorter.property] === 'string') {
              if (applicableSorter.dir === 'down') {
                return addLeadingZero(a[applicableSorter.property]).localeCompare(addLeadingZero(b[applicableSorter.property]))
              } else {
                return addLeadingZero(b[applicableSorter.property]).localeCompare(addLeadingZero(a[applicableSorter.property]))
              }
            } else if (typeof a[applicableSorter.property] === 'number') {
              if (applicableSorter.dir === 'up') {
                return a[applicableSorter.property] - b[applicableSorter.property]
              } else {
                return b[applicableSorter.property] - a[applicableSorter.property]
              }
            }
          })
        }
        return items
      })
    )
    const schanged$ = this.selection.changed.pipe(
      shareReplay(1),
    );

    const reevalbuttons = combineLatest([schanged$, this.reevaluateButtonsTrigger.pipe(startWith([null]))]).pipe(
      shareReplay(1)
    )
    this.no_deleted_files_selected$ = reevalbuttons.pipe(
      map(() => {
        return this.selection.selected.filter(
          f => (f.__typename === 'File' && !f.deleted) || f.__typename === 'Folder')
          .length === this.selection.selected.length;
      })
    )
    this.only_deleted_files_selected$ = reevalbuttons.pipe(
      map(() => {
          return this.selection.selected.filter(
            f => (f.__typename === 'File' && f.deleted))
            .length === this.selection.selected.length;
        }
      ))
    this.only_archived_files_selected$ = reevalbuttons.pipe(
      map(() => {
          return this.selection.selected.filter(
            f => (f.__typename === 'File' && f.archive))
            .length === this.selection.selected.length;
        }
      ))

    this.only_one_folder_selected$ = reevalbuttons.pipe(
      map(() => {
          if (this.selection.selected.length === 1 && this.selection.selected[0].__typename === 'Folder') {
            return this.selection.selected[0] as Folder;
          } else {
            return null;
          }
        }
      ))

    this.allowSubmitOnAllFolder$ = reevalbuttons.pipe(
      map(() => {
        if (this.selection.selected.filter(f =>
          f.__typename === 'Folder' &&
          (this.organizationService.isAllowed(
              this.poMetaService.poService.action,
              'contentplatform:asset:input.input_brefix/' + f.id.replace('s3://', '')) ||
            this.organizationService.isAllowed(
              this.poMetaService.poService.action,
              'contentplatform:asset:input.content_identifier/input_brefix://' + f.id.replace('s3://', ''))
          )).length === this.selection.selected.length) {
          return this.selection.selected.map(f => {
            return f as Folder;
          });
        }
        return null;
      })
    )

    this.indeterminate$ = combineLatest([schanged$, this.items$]).pipe(map(([s, i]) => {
      return s.source.selected.length > 0 && s.source.selected.length < i.children.length;
    }))
    this.checked$ = schanged$.pipe(map(s => {
      return s.source.selected.length > 0;
    }))
  }


  mkdir() {
    this.dialog.open(MkdirDialogComponent, {
      data: {
        folder: this.folder
      }
    });
  }

  openGenUploadLink(): void {
    this.dialog.open(GenerateUploadLinkComponent, {
      data: {
        folder: this.folder
      }
    });
  }

  openGenUploadLinkFromSelection(): void {
    this.dialog.open(GenerateUploadLinkComponent, {
      data: {
        folder: this.selection.selected[0]
      }
    });
  }


  clicked(col: number) {
    const _sorters = this.sorters.getValue();
    if (_sorters[col].dir === null) {
      _sorters[col].dir = 'up'
    } else if (_sorters[col].dir === 'up') {
      _sorters[col].dir = 'down'
    } else if (_sorters[col].dir === 'down') {
      _sorters[col].dir = 'up'
    }
    _sorters[col].order = new Date()
    this.sorters.next(_sorters)
  }

  toggleAll() {
    if (this.selection.isEmpty()) {
      this.items_sorted_and_filtered$.pipe(take(1)).subscribe(items => {
        this.selection.select(...items.children);
      })
    } else {
      this.selection.clear();
    }
  }

  onClickUndelete() {
    this.dialog.open(UndeleteDialogComponent, {
      data: {
        basedir: this.folder,
        files: this.selection.selected
      }
    })
  }

  onClickDelete() {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        basedir: this.folder,
        files: this.selection.selected
      }
    })
  }

  refreshWithFeedback() {
    const obs = this.apollo.query<Query>({
      query: FILE_FOLDER_LIST_WITH_ARCHIVE,
      variables: {
        id: this.folder.id,
        show_deleted: true
      },
      fetchPolicy: 'network-only'
    }).pipe(take(1));
    obs.subscribe(
      () => {
        this.snackBar.dismiss();
        this.snackBar.open('Refreshed.', null, {
          duration: 5000
        })

      },
      err => {
        this.banner.open('Refresh failed: ' + err, ['Close'])
      });
    return obs

  }

  copyid() {
    const clipb = []
    for (const forf of this.selection.selected) {
      clipb.push(forf.id)
    }

    navigator.clipboard.writeText(clipb.join('\n'));
    this.snackBar.open('Copied links to clipboard', null, {duration: 5000})

  }

  public move() {
    this.dialog.open(MoveFileDialogComponent, {
      data: {basefolder: this.folder.id, selection: this.selection.selected}
    });
  }

  rename() {
    this.dialog.open(RenameFileDialogComponent, {
      data: {file: this.selection.selected[0], folder: this.folder}
    });
  }

  restore() {
    this.dialog.open(ArchiveRestoreDialogComponent, {
      data: {
        files: <File[]>this.selection.selected.map((f) => f).filter(f => f.__typename === 'File' && f.archive),
        folder: this.folder
      }
    });
  }


  get width() {
    return this.sideNavService.isOpen.pipe(
      map(open => open ? 'calc(100% - 288px)' : '100%')
    )
  }


  submitSelected() {
    console.log('bangbang')
    const f$ = this.selection.selected.filter(f => f.__typename === 'Folder').map(folder => {
      console.log(folder)
      return this.apollo.query<Query>({
        query: GET_FOLDER2,
        variables: {
          id: folder.id
        }
      }).pipe(map(res => res.data.folder))
    })

    forkJoin(...f$).subscribe((folders: Folder[]) => {
      for (const folder of folders) {
        console.log('folder')
        console.log(folder)
        const preselectedAudio = new Set<string>();
        const preselectedSubtitles = new Set<string>();
        const preselectedCC = new Set<string>();
        const images = new Set<string>();
        for (const file of folder.files) {
          if (!file.tracks) {
            continue;
          }

          for (const track of file.tracks) {
            if (!track.enabled) {
              continue;
            }

            if (track.track_type === TrackType.Audio) {
              preselectedAudio.add(track.language);
            } else if (track.track_type === TrackType.Subtitle) {
              preselectedSubtitles.add(track.language);
            } else if (track.track_type === TrackType.Closedcaption) {
              preselectedCC.add(track.language);
            } else if (track.track_type === TrackType.Image) {
              images.add(file.id.split('/').pop());
            }
          }
        }

        console.log('About to add to PO');
        this.poMetaService.poService.addToPO(
          folder.id,
          [...preselectedAudio],
          [...preselectedSubtitles],
          [...preselectedCC],
          [...images]
        );

        this.snackBar.open('Added ' + folder.name + ' to PO', null,
          {duration: 5000}
        );
      }
    })
  }

  toggle(it: Folder | File) {
    this.selection.toggle(it);
  }

  viewAsAsset() {
    this.router.navigate([], {relativeTo: this.route, queryParams: {view: 'asset'}, queryParamsHandling: 'merge', replaceUrl: true})
  }
}
