<banner-outlet></banner-outlet>
<ng-container *ngIf="organizations; else loading">

  <input type="checkbox" id="cl-nav-side" [(ngModel)]="navOpened" class="cl-nav-checkbox" checked="checked">
  <nav class="cl-nav-side">
    <div class="cl-nav-backdrop" (click)="sideNavService.close()"></div>
    <div class="cl-nav-drawer">
      <div data-simplebar="data-simplebar">

        <div class="cl-nav-user">
          <a *ngIf="portalIdp.getIdpName() !== 'castlabs'">{{ userProfileService.email | async }}</a>
          <a *ngIf="portalIdp.getIdpName() === 'castlabs'"
             [href]="(prod? 'https://account.castlabs.com/' : 'https://account.test.cs.castlabs.com/') + 'account/o/' + (orgSelected$ |async)?.id ">{{ userProfileService.email | async }}</a>
        </div>

        <a class="cl-nav-organization" [routerLink]="['/']" routerLinkActive="active">
          <label>Organization</label>
          <div class="cl-nav-organization-name">
            <ng-container *ngIf="(orgSelected$ | async) as orgSelected else noorg">
              <p>{{ orgSelected.name }}</p>
              <p>via {{ portalIdp.getIdpName() }} IDP</p>
            </ng-container>
            <ng-template #noorg><p>No Organization</p></ng-template>

          </div>
        </a>

        <ng-container *ngIf="orgSelected$ | async  as orgSelected else noOrg">
          <ng-container *ngIf="licensedProducts$ | async  as licensedProducts else noOrg">
            <ng-container *ngIf="licensedProducts.length > 0 else noOrg">

              <details class="cl-dropdown cl-dropdown-dark cl-dropdown-medium">
                <summary>Content Portal</summary>

                <a class="cl-dropdown-item"
                   *ngIf="licensedProducts | contains: 'urn:janus:service:76b6e8558263431d9d766779987d64c4'"
                   [href]="'https://watermark.castlabs.com/#/o/' + orgSelected.id">
                  <span>Single Frame Watermarking</span>
                  <p class="cl-subentry">Embed and extract watermarks</p>
                </a>
                <a class="cl-dropdown-item"
                   *ngIf="licensedProducts| contains: 'urn:janus:service:79a1092a0be44b1c95a4557b8d3ee2b7'"
                   [href]="'https://fe.drmtoday.com/dashboard/' + orgSelected.id + '/index'">
                  <span>DRMtoday</span>
                  <p class="cl-subentry">Cloud DRM/CAS licensing</p>
                </a>
                <a class="cl-dropdown-item"
                   *ngIf="licensedProducts | contains: 'urn:janus:service:17edeac267d84703ab0ddd7a33d85706'"
                   [href]="'https://fe.staging.drmtoday.com/dashboard/' + orgSelected.id + '/index'">
                  <span>DRMtoday (stag)</span>
                  <p class="cl-subentry">Cloud DRM/CAS licensing</p>
                </a>
                <a class="cl-dropdown-item"
                   *ngIf="licensedProducts| contains: 'urn:janus:service:a50e21d6a5e246809b636854abfbd34d'"
                   [href]="prod ? 'https://vtk.castlabs.com': 'https://vtks.castlabs.com'">
                  <span>Video Toolkit</span>
                  <p class="cl-subentry"> Cloud encoding, packaging, watermarking and more </p></a>

                <a class="cl-dropdown-item"
                   *ngIf="
                   (licensedProducts| contains: 'urn:janus:service:5f20d44567a948a6bd49127e655ffd36') ||
                   (licensedProducts| contains: 'urn:janus:service:76bf843eb12f488f85543de28694731c') ||
                   (licensedProducts| contains: 'urn:janus:service:8a86beae73c54b0ebb426b4dfd7bb8c3')
"
                   [href]="'https://downloads.castlabs.com'">
                  <span>Downloads</span>
                  <p class="cl-subentry">PRESTOplay and more</p></a>

              </details>

            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noOrg>
          <div class="cl-dropdown cl-dropdown-dark cl-dropdown-medium">
            <summary>Content Portal</summary>
          </div>
        </ng-template>
        <div class="cl-dropdown-arrow"></div>
        <ng-container *ngIf="(orgSelected$ | async) as org">
          <a [routerLink]="['/o/' + org.id +  '/files']" *ngIf="orgSelected$ | async" routerLinkActive="active"
             class="cl-nav-item">Files
            <p class="cl-nav-item-info"> Browse available files</p></a>

          <a [routerLink]="['/o/' + org.id  +  '/search']" *ngIf="orgSelected$ | async" routerLinkActive="active"
             class="cl-nav-item">Search
            <p class="cl-nav-item-info"> Find files</p></a>

          <a [routerLink]="['/o/' +org.id  +  '/previews']" *ngIf="orgSelected$ | async" routerLinkActive="active"
             class="cl-nav-item">Previews
            <p class="cl-nav-item-info"> Preview encodes for QA</p></a>

          <a [routerLink]="['/o/' + org.id  +  '/workflows']" *ngIf="orgSelected$ | async" routerLinkActive="active"
             class="cl-nav-item">Workflows
            <p class="cl-nav-item-info"> Monitor encoding and publication</p></a>
          <a *ngIf="portalIdp.getIdpName() == 'portal'" [routerLink]="['/userprofile']" routerLinkActive="active"
             class="cl-nav-item">User profile
            <p class="cl-nav-item-info"> Set up MFA & change passwords</p></a>
          <hr class="cl-nav-separator">
        </ng-container>


        <a href="https://castlabs.atlassian.net/servicedesk/customer/portal/33" role="button" class="cl-nav-item"> Help
          Center </a>


        <hr class="cl-nav-separator">
        <a href="#" (click)="doLogout()" role="button" class="cl-nav-item cl-faded"> Sign out <i
          class="fas fa-sign-out-alt"></i></a>

        <div class="cl-spacing-medium"></div>
      </div>
    </div>
  </nav>
  <div class="cl-main">

    <ng-container *ngIf="organizations">
      <router-outlet></router-outlet>
    </ng-container>
    <app-footer></app-footer>
  </div>
</ng-container>
<app-auto-logout></app-auto-logout>

<ng-template #loading>


  <div style="position: absolute; left: calc(50% - 100px); top: calc(50% - 50px)">Logging in... <i
    class="fas fa-spinner fa-spin"></i></div>
  <button class="btn cl-btn-micro" style="position: absolute; right: 0; bottom: 0" (click)="cleanLocalStorage()">Clean
    Local Storage
  </button>

</ng-template>
